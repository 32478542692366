<template>
  <cs-button-modal-add
    id="modal-add-utente-ispezione"
    title="Aggiungi Ispettore"
    :loading="loading"
  >
    <validation-observer ref="rules">
      <b-form @submit.prevent="">

        <b-row>
          <b-col cols="12">

            <cs-validation
              label="Utente"
              rules="required"
            >
              <template slot-scope="props">
                <cs-select
                  id="utente"
                  v-model="utente"
                  label="Ispettore"
                  :options="options"
                  :state="props.errors.length === 0 ? null : false"
                />
              </template>

            </cs-validation>

          </b-col>
        </b-row>

      </b-form>
    </validation-observer>
    <b-row>
      <b-col cols="12">
        <cs-submit-button :click="add">
          Aggiungi
        </cs-submit-button>
      </b-col>
    </b-row>
  </cs-button-modal-add>
</template>

<script>

export default {
  props: {
    ispezioneId: {
      type: String,
      required: true,
    },
    utenti: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
      utente: null,
      options: [],
    }
  },
  created() {
    this.$root.$on('bv::modal::show', () => {
      this.getData()
    })
  },
  methods: {
    getData() {
      this.loading = true
      this.$remote.utenti.all()
        .then(res => {
          const users = res.data.filter(e => !this.utenti.map(a => a.id).includes(e.id))
          this.options = users.map(a => ({ id: a.id, label: a.email }))
        })
        .catch(err => this.showReposonseMessage(err))
        .finally(() => { this.loading = false })
    },
    add() {
      this.$refs.rules.validate()
        .then(success => {
          if (!success) return

          this.loading = true
          this.$remote.ispezioni.utente_add(this.ispezioneId, this.utente.id)
            .then(result => {
              this.$emit('onAdd', result.data)
              this.hideModal('modal-add-utente-ispezione')
              this.utente = null
            })
            .catch(res => this.showReposonseMessage(res))
            .finally(() => { this.loading = false })
        })
    },
  },
}
</script>
