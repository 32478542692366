<template>
  <div>
    <CsTableCard
      card-header="Elenco Difettosità"
      :rows="rows"
      :columns="columns"
      :loading="loading"
    >
      <template #topLeft>
        <b-button-group>
          <b-button
            variant="primary"
            title="Scarica CSV"
            @click="downloadDr"
          >
            <feather-icon icon="FileTextIcon" />
            Scarica CSV
          </b-button>
          <b-button
            variant="primary"
            title="Scarica PDF"
            @click="downloadPdf"
          >
            <feather-icon icon="FileTextIcon" />
            Scarica PDF
          </b-button>
        </b-button-group>
      </template>
      <template
        slot="table-row"
        slot-scope="props"
      >
        <span v-if="props.column.field === 'elementoCritico'">
          <FeatherIcon
            v-if="props.row.elementoCritico"
            icon="CheckIcon"
          />
          <span v-else>-</span>
        </span>
        <span v-else-if="props.column.field === 'elementoCriticoSismica'">
          <FeatherIcon
            v-if="props.row.elementoCriticoSismica"
            icon="CheckIcon"
          />
          <span v-else>-</span>
        </span>
        <span v-else-if="props.column.field === 'showG4'">
          <FeatherIcon
            v-if="props.row.showG4"
            icon="CheckIcon"
          />
          <span v-else>-</span>
        </span>
        <span v-else-if="props.column.field === 'showG5'">
          <FeatherIcon
            v-if="props.row.showG5"
            icon="CheckIcon"
          />
          <span v-else>-</span>
        </span>
        <span v-else-if="props.column.field === 'showPs'">
          <FeatherIcon
            v-if="props.row.showPs4 || props.row.showPs5"
            icon="CheckIcon"
          />
          <span v-else>-</span>
        </span>
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>
    </CsTableCard>
  </div>
</template>

<script>

export default {
  props: {
    ispezione: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
      columns: [
        {
          label: 'Componente',
          field: row => row.codice.substring(0, row.codice.length - 4),
          filterOptions: {
            enabled: true,
            placeholder: 'Cerca...',
          },
        },
        {
          label: 'Elemento Critico',
          field: 'elementoCritico',
          sortFn: this.presenceSort,
          filterOptions: {
            enabled: true,
            placeholder: 'Cerca...',
          },
        },
        {
          label: 'Elemento Critico Sismica',
          field: 'elementoCriticoSismica',
          sortFn: this.presenceSort,
          filterOptions: {
            enabled: true,
            placeholder: 'Cerca...',
          },
        },
        {
          label: 'DR',
          field: row => row.dr.toFixed(2),
          sortFn: this.numberSort,
          filterOptions: {
            enabled: true,
            placeholder: 'Cerca...',
          },
        },
        {
          label: 'Livello Difettosità (Dr)',
          field: 'livelloDr',
          filterOptions: {
            enabled: true,
            placeholder: 'Cerca...',
          },
        },
        {
          label: 'Difetti G=4',
          field: 'showG4',
          sortFn: this.presenceSort,
          filterOptions: {
            enabled: true,
            placeholder: 'Cerca...',
          },
        },
        {
          label: 'Intensità k2 (G=4)',
          field: 'inG4',
          filterOptions: {
            enabled: true,
            placeholder: 'Cerca...',
          },
        },
        {
          label: 'Difetti G=5',
          field: 'showG5',
          sortFn: this.presenceSort,
          filterOptions: {
            enabled: true,
            placeholder: 'Cerca...',
          },
        },
        {
          label: 'Intensità k2 (G=5)',
          field: 'inG5',
          filterOptions: {
            enabled: true,
            placeholder: 'Cerca...',
          },
        },
        {
          label: 'PS',
          field: 'showPs',
          sortFn: this.presenceSort,
          filterOptions: {
            enabled: true,
            placeholder: 'Cerca...',
          },
        },
        {
          label: 'Livello Difettosità (§4)',
          field: 'livelloDr2',
          filterOptions: {
            enabled: true,
            placeholder: 'Cerca...',
          },
        },
        {
          label: 'Livello Difettosità Sismica (§4)',
          field: 'livelloDrSismica',
          filterOptions: {
            enabled: true,
            placeholder: 'Cerca...',
          },
        },
      ],
      rows: [],
    }
  },
  created() {
    this.loading = true
    this.$remote.ispezioni.schede_densita(this.ispezione.id)
      .then(res => {
        this.rows = res.data
      })
      .catch(err => this.showReposonseMessage(err))
      .finally(() => { this.loading = false })
  },
  methods: {
    downloadDr() {
      this.loading = true
      this.$remote.ispezioni.dr_download(this.ispezione.id)
        .then(res => {
          const nome = `Difettosità ${this.ispezione.opera.codice}.csv`
          this.downloadFile(res.data, nome)
        })
        .catch(res => this.showReposonseMessage(res))
        .finally(() => { this.loading = false })
    },
    downloadPdf() {
      this.loading = true
      this.$remote.ispezioni.pdf_download(this.ispezione.id)
        .then(res => {
          const nome = `Schede G4G5 ${this.ispezione.opera.codice}.pdf`
          this.downloadFile(res.data, nome)
        })
        .catch(res => this.showReposonseMessage(res))
        .finally(() => { this.loading = false })
    },
  },
}
</script>
