<template>
  <b-tabs
    class="cs-tabs"
    card
    justified
  >
    <cs-tab
      :show="!loading"
      :is-loading="loading"
      text="Informazioni"
    >
      <anagrafica :ispezione="ispezione" />
    </cs-tab>
    <cs-tab
      :show="!loading"
      :is-loading="loading"
      text="Ispettori"
    >
      <utenti :ispezione="ispezione" />
    </cs-tab>
    <cs-tab
      :show="!loading"
      :is-loading="loading"
      text="Galleria"
    >
      <galleria :ispezione="ispezione" />
    </cs-tab>
    <cs-tab
      :show="!loading"
      :is-loading="loading"
      text="Schede"
      active
    >
      <schede :ispezione="ispezione" />
    </cs-tab>
    <cs-tab
      :show="!loading"
      :is-loading="loading"
      text="Difettosità"
    >
      <densita :ispezione="ispezione" />
    </cs-tab>
  </b-tabs>
</template>

<script>
import Anagrafica from './Anagrafica.vue'
import Utenti from './Utenti_Elenco.vue'
import Schede from './schede/Elenco.vue'
import Densita from './Densita.vue'
import Galleria from './Galleria.vue'

export default {
  components: {
    Anagrafica,
    Utenti,
    Schede,
    Densita,
    Galleria,
  },
  data() {
    return {
      loading: true,
      ispezione: {
        isApprovata: false,
        isValidata: false,
        isResponsabile: false,
        isCompilata: false,
        utenti: [],
        schede: [],
        documenti: [],
      },
    }
  },
  created() {
    if (!this.$grants.ISPEZIONI_VISUALIZZA_DETTAGLIO.can) this.$routing.ERRORE401.push()

    const { id } = this.$route.params

    this.$remote.ispezioni.single(id)
      .then(res => {
        this.ispezione = res.data

        if (!this.ispezione.isApprovata && this.$isConcessionario) this.$routing.ERRORE401.push()

        const breadcrumbData = {
          operaId: this.ispezione.opera.id,
          operaNome: this.ispezione.opera.nome,
          data: new Date(this.ispezione.dataIspezione).toLocaleDateString(),
        }
        this.setBreadcrumbData(this.$store, this.$route.name, breadcrumbData)

        this.loading = false
      })
      .catch(res => {
        this.showReposonseMessage(res)
        this.$routing.ERRORE404.push()
      })
  },
}
</script>
