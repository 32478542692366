<template>
  <b-overlay :show="loading">
    <b-card header="Ispezione">

      <b-card-body>

        <validation-observer ref="rules">
          <b-form @submit.prevent="">

            <b-row>
              <b-col cols="6">

                <cs-input
                  id="opera"
                  v-model="ispezione.opera.nome"
                  label="Opera"
                  read-only
                />

              </b-col>

              <b-col cols="6">

                <cs-input
                  id="codice"
                  v-model="ispezione.opera.codice"
                  label="Codice Opera Globale"
                  read-only
                />

              </b-col>
            </b-row>

            <b-row>
              <b-col cols="6">

                <cs-validation
                  label="Data Ispezione"
                  rules="required"
                >
                  <template slot-scope="props">
                    <cs-input
                      id="dataIpezione"
                      v-model="ispezione.dataIspezione"
                      type="date"
                      label="Data Ispezione"
                      :state="props.errors.length === 0 ? null : false"
                      :read-only="!canUpdate"
                    />
                  </template>
                </cs-validation>

              </b-col>

              <b-col cols="6">

                <cs-input
                  id="stato"
                  v-model="ispezione.stato"
                  label="Stato Ispezione"
                  read-only
                />

              </b-col>
            </b-row>

          </b-form>
        </validation-observer>

      </b-card-body>
      <b-card-footer v-if="canUpdate">
        <cs-submit-button :click="update">
          Aggiorna
        </cs-submit-button>
      </b-card-footer>
    </b-card>

    <b-row>
      <b-col
        v-if="canValida"
        cols="6"
      >
        <b-card
          header="Validazione"
        >
          <b-card-body>
            <validation-observer ref="rulesdoc">
              <b-form @submit.prevent="">

                <b-row>
                  <b-col cols="12">
                    <cs-validation
                      label="Sintesi"
                      rules="required"
                    >
                      <template slot-scope="props">

                        <b-form-file
                          v-model="documento"
                          accept=".pdf"
                          placeholder="Seleziona o trascina la sintesi qui..."
                          drop-placeholder="Trascina la sintesi qui..."
                          :state="props.errors.length > 0 ? false : null"
                        />
                      </template>
                    </cs-validation>

                  </b-col>
                </b-row>

              </b-form>
            </validation-observer>

            <cs-submit-button :click="valida">
              Valida
            </cs-submit-button>
          </b-card-body>
        </b-card>

      </b-col>
      <b-col
        v-if="canApprova"
        cols="6"
      >
        <b-card
          header="Approvazione"
        >
          <b-card-body>
            <b-form @submit.prevent="">

              <b-row>
                <b-col cols="12">
                  <b-form-file
                    v-model="documento"
                    accept=".pdf"
                    placeholder="Seleziona o trascina la sintesi qui..."
                    drop-placeholder="Trascina la sintesi qui..."
                  />

                </b-col>
              </b-row>

            </b-form>

            <cs-submit-button
              class="mt-2"
              :click="approva"
            >
              Approva
            </cs-submit-button>
          </b-card-body>
        </b-card>

      </b-col>
      <b-col cols="6">
        <b-card header="Azioni">
          <b-card-body>
            <b-row>
              <b-col cols="6">
                <b-button
                  variant="primary"
                  @click="scaricaSintesi()"
                >
                  SCARICA SINTESI
                </b-button>
              </b-col>
              <b-col cols="6">
                <b-button
                  variant="primary"
                  @click="scaricaSchedaSintesi()"
                >
                  SCARICA SCHEDE ISPEZIONE
                </b-button>
              </b-col>
            </b-row>
          </b-card-body>
        </b-card>

      </b-col>
    </b-row>
  </b-overlay>

</template>

<script>
export default {
  props: {
    ispezione: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      documento: null,
      loading: false,
      canUpdate: this.$grants.ISPEZIONI_EDITABILI_AGGIORNA_DATA.can
        && !this.ispezione.isApprovata && !this.ispezione.isValidata
        && (this.$isReferente || this.ispezione.isResponsabile),
      canApprova: this.$grants.ISPEZIONI_VALIDATE_APPROVA.can
          && !this.ispezione.isApprovata && this.ispezione.isValidata
          && this.$isReferente,
      canValida: this.$grants.ISPEZIONI_EDITABILI_SCHEDE_VALIDA.can
          && !this.ispezione.isApprovata && !this.ispezione.isValidata && this.ispezione.isCompilata
          && (this.$isReferente || this.ispezione.isResponsabile),
    }
  },
  methods: {
    scaricaSintesi() {
      this.loading = true
      this.$remote.ispezioni.sintesi_download(this.ispezione.id)
        .then(res => {
          const nome = `Sintesi ${this.ispezione.opera.codice}.pdf`
          this.downloadFile(res.data, nome)
        })
        .catch(res => this.showReposonseMessage(res))
        .finally(() => { this.loading = false })
    },
    scaricaSchedaSintesi() {
      this.loading = true
      this.$remote.ispezioni.sintesischeda_download(this.ispezione.id)
        .then(res => {
          const nome = `Schede ${this.ispezione.opera.codice}.pdf`
          this.downloadFile(res.data, nome)
        })
        .catch(res => this.showReposonseMessage(res))
        .finally(() => { this.loading = false })
    },
    valida() {
      this.$refs.rulesdoc.validate()
        .then(success => {
          if (!success) return

          this.loading = true
          this.$remote.ispezioni.valida(this.ispezione.id, this.documento)
            .then(() => {
              // eslint-disable-next-line no-restricted-globals
              location.reload()
            })
            .catch(res => {
              this.showReposonseMessage(res)
            })
            .finally(() => { this.loading = false })
        })
    },
    approva() {
      this.loading = true
      this.$remote.ispezioni.approva(this.ispezione.id, this.documento)
        .then(() => {
          // eslint-disable-next-line no-restricted-globals
          location.reload()
        })
        .catch(res => {
          this.showReposonseMessage(res)
        })
        .finally(() => { this.loading = false })
    },
    update() {
      this.$refs.rules.validate()
        .then(success => {
          if (!success) return

          this.loading = true
          this.$remote.ispezioni.update(this.ispezione)
            .then(() => {
              this.showUpdateSuccessMessage()

              const breadcrumbData = {
                operaId: this.ispezione.opera.id,
                operaNome: this.ispezione.opera.nome,
                data: new Date(this.ispezione.dataIspezione).toLocaleDateString(),
              }
              this.setBreadcrumbData(this.$store, this.$route.name, breadcrumbData)
            })
            .catch(res => this.showReposonseMessage(res))
            .finally(() => { this.loading = false })
        })
    },
  },
}
</script>
