<template>
  <div>
    <b-card header="Informazioni">
      <div style="padding: 1rem;">
        <b-row>
          <b-col @click="filter()">
            <statistic-card-vertical
              v-ripple.300="'rgba(201,241,255,0.2)'"
              icon="HashIcon"
              color="info"
              :statistic="countSchede"
              statistic-title="Schede Totali"
              class="m-0 p-0 cs-card"
            />
          </b-col>
          <b-col @click="filter(stati.IN_COMPILAZIONE.id)">
            <statistic-card-vertical
              v-ripple.300="'rgba(201,241,255,0.2)'"
              icon="BookOpenIcon"
              color="info"
              :statistic="countSchedeDaCompilare"
              statistic-title="Da Compilare"
              class="m-0 p-0 cs-card"
            />
          </b-col>
          <b-col @click="filter(stati.COMPILATA.id)">
            <statistic-card-vertical
              v-ripple.300="'rgba(201,241,255,0.2)'"
              icon="Edit3Icon"
              color="info"
              :statistic="countSchedeCompilate"
              statistic-title="Compilate"
              class="m-0 p-0 cs-card"
            />
          </b-col>
          <b-col @click="filter(stati.VALIDATA.id)">
            <statistic-card-vertical
              v-ripple.300="'rgba(201,241,255,0.2)'"
              icon="CheckIcon"
              color="info"
              :statistic="countSchedeValidate"
              statistic-title="Validate"
              class="m-0 p-0 cs-card"
            />
          </b-col>
          <b-col @click="filter(stati.APPROVATA.id)">
            <statistic-card-vertical
              v-ripple.300="'rgba(201,241,255,0.2)'"
              icon="CheckCircleIcon"
              color="info"
              :statistic="countSchedeApprovate"
              statistic-title="Approvate"
              class="m-0 p-0 cs-card"
            />
          </b-col>
        </b-row>

      </div>
    </b-card>
    <CsTableCard
      card-header="Elenco Schede"
      :rows="rows"
      :columns="columns"
      :loading="loading"
    >

      <template slot="topLeft">
        <b-row style="padding-left: 1em">
          <aggiungi
            v-if="canAddRow()"
            :opera-id="ispezione.opera.id"
            :componenti="originalRow"
            :ispezione-id="ispezione.id"
            @onAdd="addRow"
          />
          <copia
            v-if="canCopy()"
            style="margin-left: 10px"
            :ispezione="ispezione"
          />
        </b-row>

      </template>

      <template
        slot="table-row"
        slot-scope="props"
      >
        <cs-table-action-column-default :props="props">
          <cs-table-action-item-details
            :row="props.row"
            @click="detailsRow"
          />
          <cs-table-action-item
            :show="canValidateRow(props.row)"
            icon="CheckIcon"
            value="Valida"
            :row="props.row"
            @click="validaRow"
          />
          <cs-table-action-item
            :show="canInvalidateRow(props.row)"
            icon="XIcon"
            value="Invalida"
            :row="props.row"
            @click="invalidaRow"
          />
          <cs-table-action-item-delete
            :show="canDeleteRow(props.row)"
            :row="props.row"
            @click="removeRow"
          />
        </cs-table-action-column-default>

      </template>

    </CsTableCard>
  </div>
</template>

<script>
import StatisticCardVertical from '@core/components/statistics-cards/StatisticCardVertical.vue'
import Ripple from 'vue-ripple-directive'
import STATI from '@/models/statoSchedaEnum'
import Aggiungi from './Aggiungi.vue'
import Copia from './Copia.vue'

export default {
  directives: {
    Ripple,
  },
  components: {
    Aggiungi,
    Copia,
    StatisticCardVertical,
  },
  props: {
    ispezione: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
      stati: STATI,
      columns: [
        {
          label: 'Codice',
          field: row => row.codice.substring(0, 17),
          filterOptions: {
            enabled: true,
            placeholder: 'Cerca...',
          },
        },
        {
          label: 'Parte',
          field: 'parte',
          filterOptions: {
            enabled: true,
            placeholder: 'Cerca...',
          },
        },
        {
          label: 'Id. Parte',
          field: 'parteId',
          filterOptions: {
            enabled: true,
            placeholder: 'Cerca...',
          },
        },
        {
          label: 'Nr. Parte',
          field: 'parteNr',
          filterOptions: {
            enabled: true,
            placeholder: 'Cerca...',
          },
        },
        {
          label: 'Componente',
          field: 'componente',
          filterOptions: {
            enabled: true,
            placeholder: 'Cerca...',
          },
        },
        {
          label: 'Id. Comp.',
          field: 'componenteId',
          filterOptions: {
            enabled: true,
            placeholder: 'Cerca...',
          },
        },
        {
          label: 'Nr. Comp.',
          field: 'componenteNr',
          filterOptions: {
            enabled: true,
            placeholder: 'Cerca...',
          },
        },
        {
          label: 'Stato',
          field: 'stato',
          filterOptions: {
            enabled: true,
            placeholder: 'Cerca...',
          },
        },
        {
          label: 'Dr',
          field: row => row.dr.toFixed(2),
          sortFn: this.numberSort,
          filterOptions: {
            enabled: true,
            placeholder: 'Cerca...',
          },
        },
        {
          label: '',
          field: 'action',
          sortable: false,
        },
      ],
      rows: [],
      originalRow: [],
      lastFilter: null,
    }
  },
  computed: {
    countSchede() {
      return this.originalRow.length
    },
    countSchedeCompilate() {
      return this.originalRow.filter(f => f.statoId !== STATI.IN_COMPILAZIONE.id).length
    },
    countSchedeDaCompilare() {
      return this.originalRow.filter(f => f.statoId === STATI.IN_COMPILAZIONE.id).length
    },
    countSchedeValidate() {
      return this.originalRow.filter(f => f.statoId === STATI.VALIDATA.id).length
    },
    countSchedeApprovate() {
      return this.originalRow.filter(f => f.statoId === STATI.APPROVATA.id).length
    },
  },
  created() {
    this.originalRow = [...this.ispezione.schede]
    this.rows = this.originalRow
  },
  methods: {
    canCopy() {
      return this.$grants.ISPEZIONI_EDITABILI_SCHEDE_AGGIORNA.can
      && (this.$isReferente || this.ispezione.isResponsabile)
    },
    canValidateRow(row) {
      return this.$grants.ISPEZIONI_EDITABILI_SCHEDE_VALIDA.can
        && row.isCompilata
        && (this.$isReferente || this.ispezione.isResponsabile)
    },
    canInvalidateRow(row) {
      return this.$grants.ISPEZIONI_EDITABILI_SCHEDE_INVALIDA.can
        && row.isValidata && !this.ispezione.isValidata
        && (this.$isReferente || this.ispezione.isResponsabile)
    },
    canDeleteRow(row) {
      return this.$grants.ISPEZIONI_EDITABILI_SCHEDE_ELIMINA.can
        && !row.isApprovata && !row.isValidata
        && (this.$isReferente || this.ispezione.isResponsabile)
    },
    canAddRow() {
      return this.$grants.ISPEZIONI_EDITABILI_SCHEDE_AGGIUNGI.can
          && !this.ispezione.isApprovata && !this.ispezione.isValidata
          && (this.$isReferente || this.ispezione.isResponsabile)
    },
    filter(param) {
      this.lastFilter = param
      if (this.isNotNullOrUndefined(param)) {
        if (param === STATI.COMPILATA.id) this.rows = this.originalRow.filter(f => f.statoId !== STATI.IN_COMPILAZIONE.id)
        else this.rows = this.originalRow.filter(f => f.statoId === param)
      } else this.rows = this.originalRow
    },
    addRow(params) {
      this.addRowAtStart(this.originalRow, params)
      this.showAddSuccessMessage()
    },
    removeRow(params) {
      this.showDeleteSwal().then(res => {
        if (!res.value) return
        this.loading = true

        this.$remote.ispezioni.scheda_remove(this.ispezione.id, params.id)
          .then(() => {
            this.deleteRow(this.originalRow, params.originalIndex)
            this.filter(this.lastFilter)
            this.showDeleteSuccessMessage()
          })
          .catch(err => this.showReposonseMessage(err))
          .finally(() => { this.loading = false })
      })
    },
    detailsRow(params) {
      this.$routing.ISPEZIONI_DETTAGLIO_SCHEDA.push({ id: this.$route.params.id, schedaId: params.id })
    },
    validaRow(params) {
      this.showAttentionSwal().then(res => {
        if (!res.value) return
        this.loading = true

        this.$remote.schede.valida(params.id)
          .then(result => {
            this.deleteRow(this.originalRow, params.originalIndex)
            this.addRowAt(this.originalRow, result.data, params.originalIndex)
            this.filter(this.lastFilter)
            this.showSuccessMessage('Scheda validata con successo')
          })
          .catch(err => this.showReposonseMessage(err))
          .finally(() => { this.loading = false })
      })
    },
    invalidaRow(params) {
      this.showAttentionSwal().then(res => {
        if (!res.value) return
        this.loading = true

        this.$remote.schede.invalida(params.id)
          .then(result => {
            this.deleteRow(this.originalRow, params.originalIndex)
            this.addRowAt(this.originalRow, result.data, params.originalIndex)
            this.filter(this.lastFilter)
            this.showSuccessMessage('Scheda invalidata con successo')
          })
          .catch(err => this.showReposonseMessage(err))
          .finally(() => { this.loading = false })
      })
    },
  },
}
</script>
