<template>
  <cs-button-modal-add
    id="modal-add-foto-sintesi"
    title="Aggiungi Foto"
    :loading="loading"
  >

    <validation-observer ref="rules">
      <b-form @submit.prevent="">

        <b-row>
          <b-col cols="12">

            <cs-input
              id="descrizione"
              v-model="model.descrizione"
              label="Descrizione"
            />

          </b-col>
        </b-row>

        <b-row>
          <b-col cols="12">

            <cs-validation
              label="Documento"
              rules="required"
            >
              <template slot-scope="props">
                <b-form-file
                  v-model="model.file"
                  placeholder="Seleziona o trascina un file qui..."
                  drop-placeholder="Trascina un file qui..."
                  :state="props.errors.length > 0 ? false : null"
                  accept="image/*"
                />
              </template>

            </cs-validation>

          </b-col>
        </b-row>

        <b-row>
          <b-col cols="12">
            <cs-submit-button :click="add">
              Aggiungi
            </cs-submit-button>
          </b-col>
        </b-row>

      </b-form>
    </validation-observer>

  </cs-button-modal-add>
</template>

<script>

export default {
  props: {
    ispezioneId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
      model: {
        descrizione: null,
        file: null,
      },
    }
  },
  methods: {
    add() {
      this.$refs.rules.validate()
        .then(success => {
          if (!success) return

          this.loading = true

          this.$remote.ispezioni.foto_add(this.ispezioneId, this.model)
            .then(result => {
              this.$emit('onAdd', result.data)
              this.hideModal('modal-add-foto-sintesi')
              this.model.descrizione = null
              this.model.file = null
            })
            .catch(res => this.showReposonseMessage(res))
            .finally(() => { this.loading = false })
        })
    },
  },
}
</script>
