<template>
  <CsTableCard
    :loading="loading"
    card-header="Elenco Ispettori"
    :rows="rows"
    :columns="columns"
  >
    <template slot="topLeft">
      <aggiungi
        v-if="canAddUtenti"
        :utenti="rows"
        :ispezione-id="ispezione.id"
        @onAdd="addRow"
      />
    </template>

    <template
      slot="table-row"
      slot-scope="props"
    >
      <cs-table-action-column-default
        :props="props"
        :show-actions="canRemoveUtenti"
      >
        <cs-table-action-item-delete
          :show="canRemoveUtenti"
          :row="props.row"
          @click="removeRow"
        />
      </cs-table-action-column-default>

    </template>

  </CsTableCard>
</template>

<script>

import Aggiungi from './Utenti_Aggiungi.vue'

export default {
  components: {
    Aggiungi,
  },
  props: {
    ispezione: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
      columns: [
        {
          label: 'Nominativo',
          field: 'nominativo',
          filterOptions: {
            enabled: true,
            placeholder: 'Cerca...',
          },
        },
        {
          label: '',
          field: 'action',
          sortable: false,
        },
      ],
      rows: [],
      canAddUtenti: this.$grants.ISPEZIONI_EDITABILI_UTENTI_AGGIUNGI.can
          && !this.ispezione.isApprovata && !this.ispezione.isValidata
          && (this.$isReferente || this.ispezione.isResponsabile),
      canRemoveUtenti: this.$grants.ISPEZIONI_EDITABILI_UTENTI_ELIMINA.can
          && !this.ispezione.isApprovata && !this.ispezione.isValidata
          && (this.$isReferente || this.ispezione.isResponsabile),
    }
  },
  created() {
    this.rows = this.ispezione.utenti
  },
  methods: {
    addRow(params) {
      this.addRowAtStart(this.rows, params)
      this.showAddSuccessMessage()
    },
    removeRow(params) {
      this.showDeleteSwal().then(res => {
        if (!res.value) return

        this.loading = true
        this.$remote.ispezioni.utente_remove(this.ispezione.id, params.id)
          .then(() => {
            this.deleteRow(this.rows, params.originalIndex)
            this.showDeleteSuccessMessage()
          })
          .catch(err => this.showReposonseMessage(err))
          .finally(() => { this.loading = false })
      })
    },
  },
}
</script>
