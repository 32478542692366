<template>
  <cs-button-modal
    id="modal-copy-scheda-ispezione"
    title="Copia Scheda"
    :loading="loading"
    icon="CopyIcon"
  >

    <validation-observer ref="rules">
      <b-form @submit.prevent="">
        <b-row>
          <b-col cols="12">

            <cs-validation
              label="Scheda da"
              rules="required"
            >
              <template slot-scope="props">
                <cs-select
                  id="da"
                  v-model="from"
                  label="Scheda da"
                  :options="froms"
                  :reduce="r => r.id"
                  :state="props.errors.length > 0 ? false : null"
                />
              </template>

            </cs-validation>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12">

            <cs-validation
              label="Schede a"
              rules="required"
            >
              <template slot-scope="props">
                <cs-select
                  id="a"
                  v-model="to"
                  :multiple="true"
                  label="Schede a"
                  :options="tos"
                  :reduce="r => r.id"
                  :state="props.errors.length > 0 ? false : null"
                  :close-on-select="false"
                  :clear-search-on-select="false"
                />
              </template>

            </cs-validation>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>

    <b-row>
      <b-col cols="12">
        <cs-submit-button :click="add">
          Aggiungi
        </cs-submit-button>
      </b-col>
    </b-row>

  </cs-button-modal>
</template>

<script>

export default {
  props: {
    ispezione: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      loading: true,
      froms: [],
      from: null,
      tos: [],
      to: [],
    }
  },
  created() {
    this.$root.$on('bv::modal::show', ee => {
      if (ee.componentId === 'modal-copy-scheda-ispezione') {
        this.getData()
      }
    })
  },
  methods: {
    getData() {
      this.froms = this.ispezione.schede
        .filter(f => f.isCompilata)
        .map(e => ({ id: e.id, label: e.codice }))
      this.tos = this.ispezione.schede
        .filter(f => !f.isValidata && !f.isApprovata)
        .map(e => ({ id: e.id, label: e.codice }))
      this.loading = false
    },
    add() {
      this.$refs.rules.validate()
        .then(success => {
          if (!success) return
          this.loading = true
          this.$remote.schede.diffetti_update_copy(this.from, this.to)
            .then(() => {
              this.showReposonseMessage('Schede copiate, per favore aggiorna la pagina.')
              this.hideModal('modal-copy-scheda-ispezione')
              this.to = []
              this.from = null
            })
            .catch(res => this.showReposonseMessage(res))
            .finally(() => { this.loading = false })
        })
    },
  },
}
</script>
