<template>
  <cs-button-modal-add
    id="modal-add-scheda-ispezione"
    title="Aggiungi Scheda"
    :loading="loading"
  >

    <validation-observer ref="rules">
      <b-form @submit.prevent="">
        <b-row>
          <b-col cols="12">

            <cs-validation
              label="Componente"
              rules="required"
            >
              <template slot-scope="props">
                <cs-select
                  id="gruppi"
                  v-model="componente"
                  label="Componente"
                  :options="options"
                  :reduce="r => r.id"
                  :state="props.errors.length > 0 ? false : null"
                />
              </template>

            </cs-validation>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>

    <b-row>
      <b-col cols="12">
        <cs-submit-button :click="add">
          Aggiungi
        </cs-submit-button>
      </b-col>
    </b-row>

  </cs-button-modal-add>
</template>

<script>

export default {
  props: {
    operaId: {
      type: String,
      required: true,
    },
    ispezioneId: {
      type: String,
      required: true,
    },
    componenti: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      loading: true,
      componente: null,
      options: [],
    }
  },
  created() {
    this.$root.$on('bv::modal::show', ee => {
      if (ee.componentId === 'modal-add-scheda-ispezione') {
        this.getData()
      }
    })
  },
  methods: {
    getData() {
      this.$remote.opere.componenti_all(this.operaId)
        .then(res => {
          this.options = res.data
            .filter(f => !this.componenti.map(m => m.codice).includes(f.codice))
            .map(e => ({ id: e.id, label: e.codice }))
        })
        .catch(err => this.showReposonseMessage(err))
        .finally(() => { this.loading = false })
    },
    add() {
      this.$refs.rules.validate()
        .then(success => {
          if (!success) return
          this.loading = true
          this.$remote.ispezioni.scheda_add(this.ispezioneId, this.componente)
            .then(result => {
              this.$emit('onAdd', result.data)
              this.hideModal('modal-add-scheda-ispezione')
              this.componente = null
            })
            .catch(res => this.showReposonseMessage(res))
            .finally(() => { this.loading = false })
        })
    },
  },
}
</script>
